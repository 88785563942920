import React from "react";
import styled, { css } from "styled-components";
import { withTheme } from "styled-components";
import { Link } from "react-router-dom";

const SidebarContainer = styled.div`
    height: 100%;
    width: 200px;
    min-width: 200px;
    background: #002a5b;
    position: relative;
    z-index: 1;
`;

const LogoArea = styled.div`
    text-align: centre;
`;

const Title = styled.h1`
    color: white;
    font-weight: bold;
    font-size: 1.3em;
    text-align: centre;
`;

const Logo = styled.img`
    display: block;
    width: 50%;
    margin: 0 auto;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const MenuItems = styled.div`
    margin-top: 3em;
`;

const InnerMenu = styled.div`
    position: absolute;
    top: 0;
    left: 100%;
    background: red;
    width: 200px;
    height: 100%;
    display: none;
    cursor: default;
    background: #001d40;
    padding-top: 4em;
`;

const MenuItemStyles = css`
    width: 100%;
    display: inline-block;
    padding: 1em 1em 1em 1.3em;
    color: white;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: bold;

    &:hover {
        font-size: 1.1em;
    }
`;

const MenuItem = styled(Link)`
    ${MenuItemStyles};
`;

const FakeMenuItem = styled.div`
    ${MenuItemStyles};
    &:hover {
        &::after {
            color: rgba(255, 255, 255, 0.3);
            position: absolute;
            right: 10px;
            font-size: 0.8em;
            content: ">";
        }
    }
`;

const MenuItemContainer = styled.div`
    &:hover {
        ${InnerMenu} {
            display: block;
        }
    }
`;

const SidebarInner = styled.div`
    position: fixed;
    width: 200px;
    height: 100%;
    padding-top: 2em;
    background: #002a5b;

    &:hover {
        background: lighter(#002a5b);
    }
`;

const Sidebar = (theme) => {
    return (
        <SidebarContainer>
            <SidebarInner>
                <LogoArea
                    style={{
                        textAlign: "center",
                    }}
                >
                    <StyledLink to="/">
                        <Logo src="/favicon.png" />
                        <Title>DC2.0</Title>
                    </StyledLink>
                </LogoArea>

                <MenuItems>
                    <MenuItemContainer>
                        <FakeMenuItem>Data</FakeMenuItem>
                        <InnerMenu>
                            <MenuItem to="/uploads">Uploads</MenuItem>
                            <MenuItem to="/imports">Import Log</MenuItem>
                            <MenuItem to="/speeder-customer-mapping">Speeder Mapping</MenuItem>
                            <MenuItem to="/faap-j-customer-mapping">FaapJ Mapping</MenuItem>
                        </InnerMenu>
                    </MenuItemContainer>

                    <MenuItemContainer>
                        <MenuItem to="/companies">Companies</MenuItem>
                    </MenuItemContainer>

                    <MenuItemContainer>
                        <MenuItem to="/contacts">Contacts</MenuItem>
                    </MenuItemContainer>

                    <MenuItemContainer>
                        <MenuItem to="/dealers">Dealers</MenuItem>
                    </MenuItemContainer>

                    <MenuItemContainer>
                        <FakeMenuItem>Promotions</FakeMenuItem>
                        <InnerMenu>
                            <MenuItem to="/campaigns">Campaigns</MenuItem>
                            <MenuItem to="/rewards/campaigns">
                                Points Promotions
                            </MenuItem>
                        </InnerMenu>
                    </MenuItemContainer>

                    <MenuItemContainer>
                        <FakeMenuItem>Rewards</FakeMenuItem>
                        <InnerMenu>
                            <MenuItem to="/rewards/orders">
                                Reward Orders
                            </MenuItem>
                            <MenuItem to="/rewards/adjustments">
                                Points Adjustment
                            </MenuItem>
                            <MenuItem to="/rewards/snap-on-voucher-dispatch">
                                Snap-On Redemptions
                            </MenuItem>
                            {/*<MenuItem to="/rewards/reports">*/}
                            {/*    Points Summary*/}
                            {/*</MenuItem>*/}
                        </InnerMenu>
                    </MenuItemContainer>

                    <MenuItemContainer>
                        <FakeMenuItem>Reporting</FakeMenuItem>
                        <InnerMenu>
                            <MenuItem to="/reporting">Reports</MenuItem>
                            <MenuItem to="/user-reports">Report Log</MenuItem>
                        </InnerMenu>
                    </MenuItemContainer>

                    <MenuItemContainer>
                        <FakeMenuItem>User Management</FakeMenuItem>
                        <InnerMenu>
                            <MenuItem to="/users">Users</MenuItem>
                        </InnerMenu>
                    </MenuItemContainer>
                </MenuItems>
            </SidebarInner>
        </SidebarContainer>
    );
};

export default withTheme(Sidebar);
