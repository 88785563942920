import React, { useState } from "react";
import axios from "axios";
import {
    Row,
    Column,
    InputField,
    SubmitButton,
    Button,
} from "@cortexglobal/rla-components";
import { Title, Container } from "../components/generalLayoutComponents";
import CreateNewCustomer from "./CreateNewCustomer";

const CustomerSummary = ({ customerSummary }) => {
    return (
        <>
            <p>
                <strong>Distrigo Number: </strong>
                {customerSummary.trade_team_id ?? "-"}
            </p>
            <p>
                <strong>Company Number: </strong>
                {customerSummary.company_number ?? "-"}
            </p>
            <p>
                <strong>Customer: </strong>
                {customerSummary.trade_team_customer ?? "-"}
            </p>
            <p>
                <strong>Customer Type: </strong>
                {customerSummary.trade_team_customer_type ?? "-"}
            </p>
            {/*
            <p>
                <strong>Customer Address: </strong>
                {[
                    customerSummary.address_1 ?? "",
                    customerSummary.address_2 ?? "",
                    customerSummary.town ?? "",
                    customerSummary.postcode ?? "",
                ]
                    .filter((n) => n)
                    .join(", ")}
            </p>
            */}
            <p>
                <strong>Address 1: </strong>
                {customerSummary.address_1}
            </p>
            <p>
                <strong>Address 2: </strong>
                {customerSummary.address_2}
            </p>
            <p>
                <strong>Address 3: </strong>
                {customerSummary.address_3}
            </p>
            <p>
                <strong>City/Town: </strong>
                {customerSummary.town}
            </p>
            <p>
                <strong>Postcode: </strong>
                {customerSummary.postcode}
            </p>
            <p>
                <strong>Phone: </strong>
                {customerSummary.phone}
            </p>
            <p>
                <strong>Mobile: </strong>
                {customerSummary.mobile}
            </p>
            <p>
                <strong>Email: </strong>
                {customerSummary.email}
            </p>
        </>
    );
};
const ManualMatch = ({
    customerMap,
    onClose,
    handleAcceptCustomerMapping,
    loading,
    customerTypes,
}) => {
    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [showFetchSummaryError, setShowFetchSummaryError] = useState(false);
    const [customerSummary, setCustomerSummary] = useState();
    const [searchTerm, setSearchTerm] = useState();

    const [createFromCustomerMap, setCreateFromCustomerMap] = useState();

    const handleFetchCustomerSummary = () => {
        if (searchTerm) {
            setFetchingSummary(true);
            setShowFetchSummaryError(false);

            axios
                .get(`/api/v1/customers/search?term=${searchTerm}`)
                .then(({ data }) => {
                    setFetchingSummary(false);
                    setCustomerSummary(data.data);
                })
                .catch((error) => {
                    setCustomerSummary(undefined);
                    setShowFetchSummaryError(true);
                    setFetchingSummary(false);
                });
        }
    };

    const convertMap = (customerMap) => {
        return {
            map_id: customerMap.mappable_id,
            account_number: customerMap.mappable_entry_account_number,
            invoice_customer_name: customerMap.mappable_entry.trade_team_customer,
            dealer_code: customerMap.mappable_entry.dealer_code,
            customer_suggestion: customerSummary.customer_id,
            customer_from_sync_field: customerMap.mappable_entry.sync_field,
            customer_from: customerMap.mappable_entry.id,
        };
    };

    return (
        <Row>
            <Column>
                <Title>Manual Match</Title>
                <p style={{ marginBottom: "2rem" }}>
                    Use this area to either search for an existing customer or
                    create one.
                </p>
            </Column>

            <Column medium={6}>
                <Container>
                    <Title>This Customer</Title>
                    <CustomerSummary
                        customerSummary={customerMap.mappable_entry}
                    />
                    <div
                        style={{
                            marginTop: "2rem",
                            textAlign: "center",
                        }}
                    >
                        <Button
                            disabled={
                                !!customerSummary || !!createFromCustomerMap
                            }
                            onClick={() =>
                                setCreateFromCustomerMap(customerMap)
                            }
                        >
                            Use these details to Create a New Customer
                        </Button>
                    </div>
                </Container>
            </Column>

            <Column medium={6}>
                <Container>
                    {customerSummary ? (
                        <>
                            <Title>Match</Title>
                            <CustomerSummary
                                customerSummary={customerSummary}
                            />
                            <div
                                style={{
                                    margin: "2rem",
                                    textAlign: "right",
                                }}
                            >
                                <SubmitButton
                                    onClick={() => setCustomerSummary()}
                                    disabled={!customerSummary || !!loading}
                                    style={{ marginRight: "1rem" }}
                                >
                                    Cancel
                                </SubmitButton>

                                <SubmitButton
                                    loading={!!loading}
                                    onClick={() =>
                                        handleAcceptCustomerMapping([
                                            {
                                                ...convertMap(customerMap),
                                            },
                                        ])
                                    }
                                    disabled={!customerSummary || !!loading}
                                >
                                    Accept
                                </SubmitButton>
                            </div>
                        </>
                    ) : (
                        <>
                            {createFromCustomerMap?.mappable_entry ? (
                                <>
                                    <Title>Create A Customer</Title>
                                    <CreateNewCustomer
                                        initialValues={
                                            createFromCustomerMap.mappable_entry
                                        }
                                        successCallback={setCustomerSummary}
                                        cancelCallback={() =>
                                            setCreateFromCustomerMap()
                                        }
                                        customerTypes={customerTypes}
                                    />
                                </>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "1rem 0 1rem 0",
                                    }}
                                >
                                    <div>
                                        <InputField
                                            value={searchTerm}
                                            width="100%"
                                            labelWidth={100}
                                            name="searchTerm"
                                            label="Distrigo Number"
                                            onChange={({ value }) =>
                                                setSearchTerm(value)
                                            }
                                        />
                                    </div>
                                    <SubmitButton
                                        submitting={fetchingSummary}
                                        onClick={() =>
                                            handleFetchCustomerSummary(
                                                searchTerm
                                            )
                                        }
                                        style={{
                                            marginLeft: "1rem",
                                        }}
                                    >
                                        Search
                                    </SubmitButton>
                                </div>
                            )}
                        </>
                    )}
                </Container>
            </Column>
        </Row>
    );
};

export default ManualMatch;
