import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast,
} from "@cortexglobal/rla-components";
import { useHistory } from "react-router";
import {
    StyledTable,
    StyledTableRow,
    CubeLoader,
    TrueFalseIcon,
} from "@cortexglobal/lens-components";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div``;

const StyledPanel = styled(Panel)`
    padding: 0;
`;

const BoldTd = styled.td`
    font-weight: bold;
    width: 300px;
`;

const ContactTable = styled.table`
    border-spacing: 0;

    tr:nth-child(odd) {
        background: #f7f7f7;
    }

    td {
        padding: 1rem;
    }
`;

const ViewContact = ({ match }) => {
    const history = useHistory();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState();

    const getContact = () => {
        setLoading(true);

        axios
            .get(`/api/v1/contacts/${match.params.id}`)
            .then(({ data }) => {
                setContactData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting the contact.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getContact();
    }, []);

    return (
        <>
            <PageTitle
                title={
                    contactData
                        ? `${contactData.first_name} ${contactData.last_name}`
                        : `Loading Contact`
                }
                expanded
            >
                <Link to="/contacts">
                    <Button style={{ marginRight: "1em" }}>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Contacts
                    </Button>
                </Link>

                {!loading && contactData && (
                    <Link to={"/companies/" + contactData.customer_id}>
                        <Button style={{ marginRight: "1em" }}>
                            <FontAwesomeIcon icon={faCaretLeft} /> Back to
                            Company
                        </Button>
                    </Link>
                )}

                <Link to={`/contacts/edit/${match.params.id}`}>
                    <Button style={{ height: "100%" }}>Edit Contact</Button>
                </Link>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && contactData && (
                <Row expanded>
                    <Column>
                        <StyledPanel>
                            <PanelHeader>Contact Details</PanelHeader>
                            <PanelBody>
                                <ContactTable style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <BoldTd>Company ID:</BoldTd>
                                            <td>
                                                {contactData.customer_id}
                                                <Link
                                                    to={`/companies/${contactData.customer_id}`}
                                                >
                                                    <Button
                                                        style={{
                                                            marginLeft: "1em",
                                                        }}
                                                    >
                                                        View{" "}
                                                        {contactData.customer}
                                                    </Button>
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Contact Name:</BoldTd>
                                            <td>
                                                {contactData.first_name}{" "}
                                                {contactData.last_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Contact Email:</BoldTd>
                                            <td>{contactData.email}</td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Telephone:</BoldTd>
                                            <td>{contactData.phone}</td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Job Title:</BoldTd>
                                            <td>{contactData.job_title}</td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Is Primary Contact:</BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.primary_contact ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Can Nominate AR:</BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.can_nominate_ar ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Is Business Email:</BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.business_email ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>
                                                Has Legitimate Interest:
                                            </BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.legitimate_interest ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>
                                                Has AR PERKS Permission:
                                            </BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.ar_perks_permission ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Can Send Email:</BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.channel_email ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Can Send SMS:</BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.channel_sms ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Can Send Post:</BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.channel_post ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>
                                                Permissions To Communicate For
                                                Perks:
                                            </BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.permission_to_communicate_about_perks ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>
                                                Permissions To Communicate For
                                                Parts:
                                            </BoldTd>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contactData.permission_to_communicate_about_parts ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </ContactTable>
                            </PanelBody>
                        </StyledPanel>
                    </Column>
                </Row>
            )}
        </>
    );
};

export default ViewContact;
