import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    Pagination,
    SelectField,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";
import axios from "axios";
import { Title, Container } from "../components/generalLayoutComponents";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
    currentPage,
} from "@cortexglobal/lens-components";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanyLinkedAccounts = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    linkedAccounts,
    company,
    refreshCompany,
    meta,
}) => {
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [accountAndDealerNumber, setAccountAndDealerNumber] = useState({});

    const addToast = useAddToast();

    const toggleNomination = (accountAndDealerNumberPassedIn) => {
        // debugger;
        axios
            .post(
                `/api/v1/customers/${company.id}/toggle-nomination`,
                accountAndDealerNumberPassedIn
            )
            .then(({ data }) => {
                setAccountAndDealerNumber({});
                addToast({
                    type: "success",
                    content: `Account nomination status updated.`,
                    showFor: 5000,
                });
                refreshCompany();
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error setting the nomination status of this account.",
                    showFor: 5000,
                });
            })
            .finally(() => {});
    };

    const handleUnlink = () => {
        axios
            .post(`/api/v1/customers/${company.id}/un-link-accounts`, {
                account_code: accountAndDealerNumber.account_code,
                dealer_code: accountAndDealerNumber.dealer_code,
                type: accountAndDealerNumber.type,
            })
            .then(({ data }) => {
                setShowWarningModal(null);
                setAccountAndDealerNumber({});
                addToast({
                    type: "success",
                    content: "Customer successfully unlinked.",
                    showFor: 5000,
                });
                refreshCompany();
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error accepting unlinking customer.",
                    showFor: 5000,
                });
            })
            .finally(() => {});
    };

    return (
        <>
            <StyledTable className="linked-accounts-table">
                <thead>
                    <tr>
                        <StyledTableHeader name="id">ID</StyledTableHeader>
                        <StyledTableHeader name="account_code">
                            Account Code
                        </StyledTableHeader>
                        <StyledTableHeader name="company">
                            Company From Invoice
                        </StyledTableHeader>
                        <StyledTableHeader name="dealer_code">
                            Dealer Code
                        </StyledTableHeader>
                        <StyledTableHeader name="post_code">
                            Post Code
                        </StyledTableHeader>
                        <StyledTableHeader name="dealer_code">
                            Dealer Name
                        </StyledTableHeader>
                        <StyledTableHeader name="dealer_code">
                            Matched Method
                        </StyledTableHeader>
                        {company && company.type == "Authorised Repairer" && (
                            <StyledTableHeader name="nominated">
                                Nominated for AR PERKS
                            </StyledTableHeader>
                        )}
                        <StyledTableHeader name="dealer_code">
                            Actions
                        </StyledTableHeader>
                    </tr>
                </thead>
                <tbody>
                    {linkedAccounts.map((linkedAccount) => (
                        <StyledTableRow key={linkedAccount.id}>
                            <td>{linkedAccount.id}</td>
                            <td>{linkedAccount.account_code}</td>
                            <td>
                                {linkedAccount.customer_from_invoice
                                    ? linkedAccount.customer_from_invoice
                                    : ""}
                            </td>
                            <td>{linkedAccount.dealer_code}</td>
                            <td>{linkedAccount.post_code}</td>
                            <td>{linkedAccount.dealer_name}</td>
                            <td>{linkedAccount.matched_method}</td>
                            {company &&
                                company.type == "Authorised Repairer" && (
                                    <td>
                                        {linkedAccount.nominated_for_ar_perks
                                            ? "Yes"
                                            : "No"}
                                    </td>
                                )}
                            <td>
                                {company &&
                                    company.type == "Authorised Repairer" && (
                                        <Button
                                            onClick={() => {
                                                toggleNomination({
                                                    account_code:
                                                        linkedAccount.account_code,
                                                    dealer_code:
                                                        linkedAccount.dealer_code,
                                                    type: linkedAccount.matched_method,
                                                });
                                            }}
                                        >
                                            {linkedAccount.nominated_for_ar_perks
                                                ? "De-nominate"
                                                : "Nominate"}
                                        </Button>
                                    )}{" "}
                                <Button
                                    onClick={() => {
                                        setShowWarningModal(true);
                                        setAccountAndDealerNumber({
                                            account_code:
                                                linkedAccount.account_code,
                                            dealer_code:
                                                linkedAccount.dealer_code,
                                            type: linkedAccount.matched_method,
                                        });
                                    }}
                                >
                                    Unlink
                                </Button>
                            </td>
                        </StyledTableRow>
                    ))}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PerPageContainer>
                    Per Page:
                    <SelectField
                        name="perPage"
                        style={{ margin: "0 0 0 1em" }}
                        value={perPageValue}
                        options={[
                            { value: 10, text: "10" },
                            { value: 20, text: "20" },
                            { value: 30, text: "30" },
                            { value: 40, text: "40" },
                            { value: 50, text: "50" },
                        ]}
                        onChange={handlePerPage}
                    />
                </PerPageContainer>
                <Pagination
                    currentPage={currentPage(selectedPageValue)}
                    total={meta.total}
                    pageCount={meta.last_page}
                    onPageChange={handlePageChange}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
                <TotalContainer>Total: {meta.total}</TotalContainer>
            </PaginationContainer>

            <Modal
                visible={!!showWarningModal}
                onClose={() => setShowWarningModal(null)}
                maxWidth="40%"
            >
                <Container style={{ marginTop: "2rem" }}>
                    <p style={{ textTransform: "uppercase" }}>
                        Are you sure you want to un link
                    </p>
                    <p>
                        Account Number : {accountAndDealerNumber?.account_code}
                    </p>
                    <p>Dealer Code : {accountAndDealerNumber?.dealer_code}</p>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            onClick={() => {
                                setShowWarningModal(null);
                            }}
                        >
                            Cancel
                        </Button>
                        <SubmitButton
                            onClick={() => {
                                handleUnlink();
                            }}
                        >
                            Unlink
                        </SubmitButton>
                    </div>
                </Container>
            </Modal>
        </>
    );
};

export default CompanyLinkedAccounts;
