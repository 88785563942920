import React, {
    useEffect,
    useState,
} from "react";
import {
    Link,
    useHistory
} from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    FormikInput,
    FormikSelect,
    FormikDatePicker,
    FormikCheckbox,
    FormikTextarea,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CubeLoader } from "@cortexglobal/lens-components";
import CompanyForm from "./CompanyForm";

const ContactContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const CreateCompany = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState();
    const history = useHistory();
    const addToast = useAddToast();

    const handleSubmit = (value) => {
        setLoading(true);
        axios
            .post(`/api/v1/customers`, {
                company_number: value.company_number,
                trade_team_id: value.trade_team_id,
                trade_team_customer: value.trade_team_customer,
                alternative_customer_name: value.alternative_customer_name,
                trade_team_customer_type: value.trade_team_customer_type,
                rrdi_code: value.rrdi_code,
                dealer_code_for_leads: value.dealer_code_for_leads,
                address_1: value.address_1,
                address_2: value.address_2,
                town: value.town,
                county: value.county,
                postcode: value.postcode,
                email: value.email,
                mobile: value.mobile,
                phone: value.phone,
                vat: value.vat,
                brand_specialist: value.brand_specialist,
                mot_centre: value.mot_centre,
                number_of_bays: value.number_of_bays,
                latest_change_origin: 'datacentre-frontend',
                number_of_technicians: value.number_of_technicians,
                number_of_trade_team_programmes: value.number_of_trade_team_programmes,
                average_vehicles_per_week: value.average_vehicles_per_week,
                average_weekly_spend_brand_parts: value.average_weekly_spend_brand_parts,
                average_age_vehicles: value.average_age_vehicles,
            })
            .then(({ data }) => {
                history.push(`/companies`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error creating your company.",
                    showFor: 5000
                });
            });
    };

    useEffect(() => {}, []);

    return (
                <>
                    <PageTitle title={`Create: Company`} expanded>
                        <Link to={`/companies`}>
                            <Button>
                                <FontAwesomeIcon icon={ faCaretLeft } /> Back to Companies
                            </Button>
                        </Link>
                    </PageTitle>

                    {loading && <CubeLoader />}

                    {!loading && (
                    <ContactContainer>
                        <Row>
                            <Column>
                                <CompanyForm handleSubmit={handleSubmit} loading={loading} values={companyData}/>
                            </Column>
                        </Row>
                    </ContactContainer>
                    )}
        </>
    );
}

export default CreateCompany;