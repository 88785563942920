import React, { useEffect, useState } from "react";
import {Button, Modal, Pagination, SelectField, useAddToast} from "@cortexglobal/rla-components";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
    currentPage
} from "@cortexglobal/lens-components";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanyMergeLog = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    linkedAccounts,
    mergeLog,
    meta,
}) => {
    return (
        <>
            <StyledTable
                className="linked-accounts-table"
            >
                <thead>
                <tr>
                    <StyledTableHeader
                        name="customer_from"
                    >
                        Company From
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="customer_to"
                    >
                        Company To (This Company)
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="status"
                    >
                        Status
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="dealer_code"
                    >
                        Reason For Merge
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="error_details"
                    >
                        Error Details
                    </StyledTableHeader>
                </tr>
                </thead>
                <tbody>
                {mergeLog.map((mergeLog) => (
                    <StyledTableRow
                        key={mergeLog.id}
                    >
                        <td>
                            {mergeLog.customer_from.trade_team_customer} - {mergeLog.customer_from.trade_team_id}
                        </td>
                        <td>
                            {mergeLog.customer_to.trade_team_customer} - {mergeLog.customer_to.trade_team_id}
                        </td>
                        <td>
                            {mergeLog.status}
                        </td>
                        <td>
                            {mergeLog.reason}
                        </td>
                        <td>
                            {mergeLog.error}
                        </td>
                    </StyledTableRow>
                ))}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PerPageContainer>
                    Per Page:
                    <SelectField
                        name="perPage"
                        style={{ margin: '0 0 0 1em' }}
                        value={perPageValue}
                        options={[
                            {value: 10, text: '10'},
                            {value: 20, text: '20'},
                            {value: 30, text: '30'},
                            {value: 40, text: '40'},
                            {value: 50, text: '50'},
                        ]}
                        onChange={handlePerPage}
                    />
                </PerPageContainer>
                <Pagination
                    currentPage={currentPage(selectedPageValue)}
                    total={meta.total}
                    pageCount={meta.last_page}
                    onPageChange={handlePageChange}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
                <TotalContainer>Total: {meta.total}</TotalContainer>
            </PaginationContainer>
        </>
    );
};

export default CompanyMergeLog;