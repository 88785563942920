import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    useAddToast,
    InputField,
    SelectField,
    Pagination,
    Modal,
    DatePicker,
} from "@cortexglobal/rla-components";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
    StyledTable,
    StyledTableHeader,
    StyledTableRow,
    CubeLoader,
    ButtonDropdown,
    currentPage,
} from "@cortexglobal/lens-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import queryString from "querystring";
import ManualMatch from "./ManualMatch";
import { debounce } from "lodash";
import "react-toggle/style.css";
const SearchSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
`;

const MappableEntryData = styled.span`
    color: #555;
    font-style: italic;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SelectedCustomerMapping = styled.div`
    position: absolute;
    background: white;
    bottom: -10px;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    align-items: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    width: calc(100% - 150px);
    position: fixed;
`;

const ViewCustomerMappingSideColumn = styled.div`
    width: 24%;
    margin-right: 1rem;
    font-weight: bold;
`;

const ViewCustomerMappingMainColumn = styled.div`
    width: 38%;
`;

const ViewCustomerMappingTitle = styled.div`
    display: flex;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
`;

const FilterContainer = styled.div`
    margin-top: 1rem;
    border-style: solid;
    background: #eee;
    padding: 2rem 1rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const Filters = styled.div`
    margin-top: 1rem;
    background: #eee;
    padding: 2rem 1rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const ViewCustomerMappingRow = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const CustomerMapping = (props) => {
    const history = useHistory();
    const addToast = useAddToast();

    const { orderBy, orderDir, search, searchBy, queueType, perPage, page } =
        queryString.parse(useLocation().search.substring(1));

    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 0 : page
    );

    const [data, setData] = useState({
        data: [],
    });

    const [loading, setLoading] = useState(true);
    const [mappingFiltersLoading, setMappingFiltersLoading] = useState(true);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [speederCustomerTypes, setSpeederCustomerTypes] = useState([]);
    const [speederCustomerTypeOptIn, setSeederCustomerTypeOptIt] =
        useState(false);
    const [postCodeOptIn, setPostCodeOptIn] = useState(false);
    const [dealerNames, setDealerNames] = useState([]);

    const [multiLoading, setMultiLoading] = useState(false);

    const [selectedCustomerMapping, setSelectedCustomerMapping] = useState([]);

    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);

    const [viewCustomerMapping, setViewCustomerMapping] = useState();

    const [manualMatch, setManualMatch] = useState(null);

    const [filtersShowing, setFiltersShowing] = useState(false);
    const [filters, setFilters] = useState({
        date_from: "",
        date_to: "",
        distributor: "",
        customer_type: "",
        postcode: "",
        postcode_opt_in: false,
        customer_type_opt_in: false,
        account_number: "",
        business_name: "",
    });

    const [queueTypeValue, setQueueTypeValue] = useState(queueType);

    const handleSearch = (value) => {
        debounceSearch(() => {
            setParams(value);
            getCustomerMapping(value);
        });
    };

    const getCustomerMapping = (newData) => {
        if (!newData && !queueTypeValue) {
            newData = { queue_type: "queue" };
            setQueueTypeValue("queue");
        }

        if (!newData || !("queue_type" in newData)) {
            newData = { queue_type: queueTypeValue, ...newData };
        }

        setLoading(true);

        axios
            .get(`/api/v1/customers/mapping-intervention`, {
                params: {
                    backlog: false,
                    queue_type: queueTypeValue,
                    page: selectedPageValue,
                    source: "speeder",
                    per_page: perPageValue ? perPageValue : 20,
                    filters: filters,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setData(data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting customer mapping.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    const generateActions = (customerMap) => {
        if (customerMap?.mappable_entry?.dealer_name === "") {
            return [];
        }

        if (queueType && queueType === "accepted") {
            return [
                {
                    name: "View",
                    onClick: () => setViewCustomerMapping(customerMap),
                },
            ];
        }

        return [
            {
                name: "Accept",
                onClick: () => {
                    handleAcceptCustomerMapping([
                        {
                            ...convertMap(customerMap),
                        },
                    ]);
                },
            },
            {
                name: "Reject",
                onClick: () => {
                    handleRejectCustomerMapping([
                        {
                            ...convertMap(customerMap),
                        },
                    ]);
                },
            },
            {
                name: "Manual Match",
                onClick: () => setManualMatch(customerMap),
            },
            {
                name: "View",
                onClick: () => setViewCustomerMapping(customerMap),
            },
        ];
    };

    const getMappingFilters = () => {
        axios
            .get(`/api/v1/customers-mapping-filters`)
            .then(({ data }) => {
                setDealerNames(data.data.dealers);
                setCustomerTypes(data.data.customer_types);
                setSpeederCustomerTypes(data.data.speeder_types);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error getting customer mapping filters.",
                    showFor: 5000,
                });
            });
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    useEffect(() => {
        getMappingFilters();
        handleSearch();
    }, [filters]);

    const convertMap = (customerMap) => {
        return {
            map_id: customerMap.mappable_id,
            account_number: customerMap.mappable_entry_account_number,
            invoice_customer_name:
                customerMap.mappable_entry.trade_team_customer,
            dealer_code: customerMap.mappable_entry.dealer_code,
            customer_suggestion: customerMap.customer_suggestion.customer_id,
            customer_from_sync_field: customerMap.mappable_entry.sync_field,
            customer_from: customerMap.mappable_entry.id,
        };
    };

    const handleSelectAll = () => {
        setSelectedCustomerMapping(
            data.data.map((customerMap) => convertMap(customerMap))
        );
    };

    const handleClickMapping = (customerMap) => {
        if (customerMap?.mappable_entry?.dealer_name === "") {
            return;
        }

        if (
            selectedCustomerMapping.some(
                ({ map_id }) => map_id === customerMap.mappable_id
            )
        ) {
            setSelectedCustomerMapping([
                ...selectedCustomerMapping.filter(
                    (selectedCustomerMap) =>
                        selectedCustomerMap.map_id !== customerMap.mappable_id
                ),
            ]);
        } else {
            setSelectedCustomerMapping([
                ...selectedCustomerMapping,
                {
                    ...convertMap(customerMap),
                },
            ]);
        }
    };

    const cancelAllLoading = () => {
        setMultiLoading(false);
        setLoading(false);
        setSelectedCustomerMapping([]);
    };

    const handleAcceptCustomerMapping = (customerMapping) => {
        const customerMap = customerMapping
            .filter(({ customer_suggestion }) => !!customer_suggestion)
            .map((customerMap) => {
                const { id, ...newMap } = customerMap;
                return {
                    ...newMap,
                    decision: "accepted",
                };
            });

        axios
            .put(`/api/v1/customers/mapping-intervention`, {
                mappings: customerMap,
            })
            .then(({ data }) => {
                handleSearch();
                setManualMatch(null);
                addToast({
                    type: "success",
                    content: "Customer successfully mapped.",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error accepting customer mapping.",
                    showFor: 5000,
                });
                setLoading(false);
            })
            .finally(() => {
                cancelAllLoading();
            });
    };

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleRejectCustomerMapping = (customerMapping) => {
        const customerMap = customerMapping.map((customerMap) => {
            const { ...newMap } = customerMap;
            return {
                ...newMap,
                decision: "rejected",
            };
        });

        axios
            .put(`/api/v1/customers/mapping-intervention`, {
                mappings: customerMap,
            })
            .then(({ data }) => {
                cancelAllLoading();
                handleSearch();

                // setData(data);
            })
            .catch((e) => {
                cancelAllLoading();
                addToast({
                    type: "alert",
                    content: "There was an error rejecting customer mapping.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            per_page: perPageValue,
            page: selectedPageValue,
            queue_type: queueTypeValue,
            ...newData,
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${
                data.order_by ? `&orderBy=${data.order_by}` : ""
            }${data.order_dir ? `&orderDir=${data.order_dir}` : ""}${
                data.queue_type ? `&queueType=${data.queue_type}` : ""
            }`,
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        handleSearch({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            handleSearch({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        handleSearch({
            page: selected + 1,
        });
    };

    return (
        <>
            <PageTitle title="Speeder Customer Mapping" expanded>
                <SelectField
                    name="queue_type"
                    value={queueTypeValue}
                    onChange={({ value }) => {
                        setQueueTypeValue(value);
                        setParams({
                            queue_type: value,
                            page: 1,
                        });
                        handleSearch({
                            queue_type: value,
                            page: 1,
                        });
                        setSelectedPageValue(1);
                    }}
                    style={{ marginBottom: "0" }}
                    options={[
                        {
                            text: "Queue",
                            value: "queue",
                        },
                        {
                            text: "Accepted",
                            value: "accepted",
                        },
                        {
                            text: "Rejected",
                            value: "rejected",
                        },
                    ]}
                />
            </PageTitle>

            <Row expanded collapse>
                <Column collapse>
                    <Row expanded>
                        <Column>
                            <SearchSection>
                                <Button onClick={() => handleSelectAll()}>
                                    Select All
                                </Button>
                                <SearchSection>
                                    <div>
                                        <DatePicker
                                            allowEmpty={true}
                                            label="From"
                                            name="From"
                                            value={filters.date_from ?? ""}
                                            onChange={({ value }) => {
                                                setFilters({
                                                    ...filters,
                                                    date_from: value,
                                                });
                                                handleSearch();
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <DatePicker
                                            allowEmpty={true}
                                            label="To"
                                            name="To"
                                            value={filters.date_to ?? ""}
                                            onChange={({ value }) => {
                                                setFilters({
                                                    ...filters,
                                                    date_to: value,
                                                });
                                                handleSearch();
                                            }}
                                        />
                                    </div>
                                    <Button
                                        onClick={() =>
                                            setFiltersShowing(!filtersShowing)
                                        }
                                    >
                                        Toggle Filters{" "}
                                        <FontAwesomeIcon
                                            icon={
                                                filtersShowing
                                                    ? faCaretUp
                                                    : faCaretDown
                                            }
                                            size="lg"
                                        />
                                    </Button>
                                </SearchSection>
                            </SearchSection>

                            {filtersShowing && (
                                <Filters>
                                    <InputField
                                        name="business_name"
                                        onChange={({ value }) =>
                                            setFilters({
                                                ...filters,
                                                business_name: value,
                                            })
                                        }
                                        value={filters.business_name}
                                        label="Business Name"
                                    />

                                    <InputField
                                        name="account_number"
                                        onChange={({ value }) =>
                                            setFilters({
                                                ...filters,
                                                account_number: value,
                                            })
                                        }
                                        value={filters.account_number}
                                        label="Account Number"
                                    />

                                    <InputField
                                        name="postcode"
                                        onChange={({ value }) =>
                                            setFilters({
                                                ...filters,
                                                postcode: value,
                                                postcode_opt_in: postCodeOptIn,
                                            })
                                        }
                                        value={filters.postcode}
                                        label="Postcode"
                                    />
                                    <Toggle
                                        id="opt-in-postcode"
                                        defaultChecked={postCodeOptIn}
                                        aria-labelledby="opt-in-postcode-label"
                                        onChange={(event) => {
                                            setFilters({
                                                ...filters,
                                                postcode_opt_in:
                                                    event.target.checked,
                                            });
                                            setPostCodeOptIn(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <span id="opt-in-postcode">
                                        Postcode Opt In/Out
                                    </span>

                                    <SelectField
                                        name=""
                                        label="Distributor"
                                        value={filters.distributor}
                                        options={dealerNames}
                                        onChange={({ value }) =>
                                            setFilters({
                                                ...filters,
                                                distributor: value,
                                            })
                                        }
                                    />

                                    <SelectField
                                        name=""
                                        label="Speeder Customer Type"
                                        value={filters.customer_type}
                                        options={speederCustomerTypes}
                                        onChange={({ value }) =>
                                            setFilters({
                                                ...filters,
                                                customer_type: value,
                                                customer_type_opt_in:
                                                    speederCustomerTypeOptIn,
                                            })
                                        }
                                    />

                                    <Toggle
                                        id="opt-in-speeder-types"
                                        defaultChecked={
                                            speederCustomerTypeOptIn
                                        }
                                        aria-labelledby="opt-in-speeder-types-label"
                                        onChange={(event) => {
                                            setFilters({
                                                ...filters,
                                                customer_type_opt_in:
                                                    event.target.checked,
                                            });
                                            setSeederCustomerTypeOptIt(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <span id="opt-in-speeder-types">
                                        Speeder Customer Type Opt In/Out
                                    </span>

                                    <Button onClick={() => handleSearch()}>
                                        Filter
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setFilters({
                                                date_from: "",
                                                date_to: "",
                                                distributor: "",
                                                customer_type: "",
                                                postcode: "",
                                                account_number: "",
                                                business_name: "",
                                                postcode_opt_in: false,
                                                customer_type_opt_in: false,
                                            });
                                            handleSearch();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </Filters>
                            )}
                        </Column>
                    </Row>
                    <Panel style={{ paddingTop: "0" }}>
                        <StyledTable
                            selectable={true}
                            className="dashboard-table"
                        >
                            <thead style={{ position: "sticky", top: "0" }}>
                                <tr>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "dealer_code"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        onSortChange={handleSortChange}
                                        name="dealer_code"
                                    >
                                        Dealer Code
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue ===
                                            "trade_team_customer"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        onSortChange={handleSortChange}
                                        name="trade_team_customer"
                                    >
                                        Business Name
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue ===
                                            "alternative_customer_name"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        onSortChange={handleSortChange}
                                        name="alternative_customer_name"
                                    >
                                        Alternative Business Name
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "account_number"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        onSortChange={handleSortChange}
                                        name="account_number"
                                    >
                                        Account Number
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "town"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        onSortChange={handleSortChange}
                                        name="town"
                                    >
                                        Town
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "postcode"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        onSortChange={handleSortChange}
                                        name="postcode"
                                    >
                                        Postcode
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue ===
                                            "trade_team_customer_type"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        onSortChange={handleSortChange}
                                        name="trade_team_customer_type"
                                    >
                                        Customer Type
                                    </StyledTableHeader>

                                    <StyledTableHeader />
                                </tr>
                            </thead>

                            {!loading && (
                                <tbody>
                                    {data.data.map((customerMap) => (
                                        <StyledTableRow
                                            selected={selectedCustomerMapping.some(
                                                (selectedCustomerMap) =>
                                                    selectedCustomerMap.map_id ===
                                                    customerMap.mappable_id
                                            )}
                                            className={
                                                selectedCustomerMapping.some(
                                                    (selectedCustomerMap) =>
                                                        selectedCustomerMap.map_id ===
                                                        customerMap.mappable_id
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            onClick={() =>
                                                handleClickMapping(customerMap)
                                            }
                                            key={customerMap.mappable_id}
                                        >
                                            <td style={{ width: "10%" }}>
                                                <MappableEntryData>
                                                    {customerMap.mappable_entry
                                                        .dealer_code ? (
                                                        customerMap
                                                            .mappable_entry
                                                            .dealer_code
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </MappableEntryData>
                                                <br />
                                                {customerMap.customer_suggestion
                                                    .dealer_code ? (
                                                    customerMap
                                                        .customer_suggestion
                                                        .dealer_code
                                                ) : (
                                                    <>&nbsp;</>
                                                )}
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {customerMap.mappable_entry
                                                        .trade_team_customer ? (
                                                        customerMap
                                                            .mappable_entry
                                                            .trade_team_customer
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </MappableEntryData>
                                                <br />
                                                {customerMap.customer_suggestion
                                                    .trade_team_customer ? (
                                                    customerMap
                                                        .customer_suggestion
                                                        .trade_team_customer
                                                ) : (
                                                    <>&nbsp;</>
                                                )}
                                            </td>
                                            <td>
                                                <br />
                                                {customerMap.customer_suggestion
                                                    .alternative_customer_name ? (
                                                    customerMap
                                                        .customer_suggestion
                                                        .alternative_customer_name
                                                ) : (
                                                    <>&nbsp;</>
                                                )}
                                            </td>
                                            <td style={{ width: "10%" }}>
                                                <MappableEntryData>
                                                    {customerMap.mappable_entry_account_number ? (
                                                        customerMap.mappable_entry_account_number
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {customerMap.mappable_entry
                                                        .town ? (
                                                        customerMap
                                                            .mappable_entry.town
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </MappableEntryData>
                                                <br />
                                                {customerMap.customer_suggestion
                                                    .town ? (
                                                    customerMap
                                                        .customer_suggestion
                                                        .town
                                                ) : (
                                                    <>&nbsp;</>
                                                )}
                                            </td>
                                            <td style={{ width: "10%" }}>
                                                <MappableEntryData>
                                                    {customerMap.mappable_entry
                                                        .postcode ? (
                                                        customerMap
                                                            .mappable_entry
                                                            .postcode
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </MappableEntryData>
                                                <br />
                                                {customerMap.customer_suggestion
                                                    .postcode ? (
                                                    customerMap
                                                        .customer_suggestion
                                                        .postcode
                                                ) : (
                                                    <>&nbsp;</>
                                                )}
                                            </td>
                                            <td style={{ minWidth: "30%" }}>
                                                <MappableEntryData>
                                                    {customerMap.mappable_entry
                                                        .trade_team_customer_type ? (
                                                        customerMap
                                                            .mappable_entry
                                                            .trade_team_customer_type
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </MappableEntryData>
                                                <br />
                                                {customerMap.customer_suggestion
                                                    .trade_team_customer_type ? (
                                                    customerMap
                                                        .customer_suggestion
                                                        .trade_team_customer_type
                                                ) : (
                                                    <>&nbsp;</>
                                                )}
                                            </td>
                                            <td>
                                                <ButtonDropdown
                                                    hidePrimaryAction={true}
                                                    name="primary"
                                                    disabled={!!loading}
                                                    actions={generateActions(
                                                        customerMap
                                                    )}
                                                />
                                            </td>
                                        </StyledTableRow>
                                    ))}
                                </tbody>
                            )}
                        </StyledTable>

                        {loading && <CubeLoader />}

                        {!loading && !data.data.length && (
                            <p
                                style={{
                                    textAlign: "center",
                                    margin: "3em 0",
                                }}
                            >
                                No results
                            </p>
                        )}

                        {!loading && data.meta && (
                            <PaginationContainer>
                                <PerPageContainer>
                                    Per Page:
                                    <SelectField
                                        name="perPage"
                                        style={{ margin: "0 0 0 1em" }}
                                        value={perPageValue}
                                        options={[
                                            { value: 10, text: "10" },
                                            { value: 20, text: "20" },
                                            { value: 30, text: "30" },
                                            { value: 40, text: "40" },
                                            { value: 50, text: "50" },
                                        ]}
                                        onChange={handlePerPage}
                                    />
                                </PerPageContainer>
                                <Pagination
                                    currentPage={currentPage(selectedPageValue)}
                                    total={data.meta.total}
                                    pageCount={data.meta.last_page}
                                    onPageChange={handlePageChange}
                                    previousLabel="&laquo;"
                                    nextLabel="&raquo;"
                                />
                                <TotalContainer>
                                    Total: {data.meta.total}
                                </TotalContainer>
                            </PaginationContainer>
                        )}
                    </Panel>
                </Column>
            </Row>

            {!!selectedCustomerMapping.length && (
                <SelectedCustomerMapping>
                    <span>
                        <b>
                            Selected {selectedCustomerMapping.length} Customer
                            Mapping(s)
                        </b>
                    </span>
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "2em" }}>
                            <ButtonDropdown
                                disabled={!!multiLoading}
                                onClick={() => setSelectedCustomerMapping([])}
                                actions={[]}
                                name="primary"
                            >
                                Cancel
                            </ButtonDropdown>
                        </span>
                        {queueTypeValue && queueTypeValue !== "accepted" && (
                            <>
                                <span>
                                    <ButtonDropdown
                                        disabled={!!multiLoading}
                                        onClick={() => {
                                            setMultiLoading(true);
                                            handleAcceptCustomerMapping(
                                                selectedCustomerMapping
                                            );
                                        }}
                                        name="primary"
                                    >
                                        Accept
                                    </ButtonDropdown>
                                </span>
                                <span style={{ marginRight: "2em" }}>
                                    <ButtonDropdown
                                        disabled={!!multiLoading}
                                        onClick={() => {
                                            setMultiLoading(true);
                                            handleRejectCustomerMapping(
                                                selectedCustomerMapping
                                            );
                                        }}
                                        name="primary"
                                    >
                                        Reject
                                    </ButtonDropdown>
                                </span>
                            </>
                        )}

                        {multiLoading && (
                            <span
                                style={{
                                    marginLeft: "2em",
                                }}
                            >
                                <CubeLoader size={20} margin="0" />
                            </span>
                        )}
                    </span>
                </SelectedCustomerMapping>
            )}

            <Modal
                visible={!!manualMatch}
                onClose={() => setManualMatch(null)}
                maxWidth="90%"
            >
                <ManualMatch
                    customerMap={manualMatch}
                    onClose={() => setManualMatch(null)}
                    handleAcceptCustomerMapping={handleAcceptCustomerMapping}
                    loading={!!multiLoading}
                    customerTypes={customerTypes}
                />
            </Modal>

            {!!viewCustomerMapping && (
                <Modal
                    visible={!!viewCustomerMapping}
                    onClose={() => setViewCustomerMapping()}
                >
                    <ViewCustomerMappingTitle>
                        <ViewCustomerMappingSideColumn />
                        <ViewCustomerMappingMainColumn>
                            Suggested Mapping
                        </ViewCustomerMappingMainColumn>
                        <ViewCustomerMappingMainColumn>
                            Incoming Record
                        </ViewCustomerMappingMainColumn>
                    </ViewCustomerMappingTitle>
                    <div>
                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Distrigo Number
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.customer_suggestion
                                        .trade_team_id
                                }
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.mappable_entry
                                        .trade_team_id
                                }
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Company Number
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.customer_suggestion
                                        .company_number
                                }
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.mappable_entry
                                        .company_number
                                }
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                RRDI
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {/*  Not sure what goes here  */}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {/*  Not sure what goes here  */}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Business Name
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.customer_suggestion
                                        .trade_team_customer
                                }
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.mappable_entry
                                        .trade_team_customer
                                }
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Alternative Business Name
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.customer_suggestion
                                        .trade_team_customer
                                }
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.mappable_entry
                                        .trade_team_customer
                                }
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Postcode
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {
                                    viewCustomerMapping.customer_suggestion
                                        .postcode
                                }
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.mappable_entry.postcode}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Address
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.customer_suggestion.town}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.mappable_entry.town}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>
                    </div>
                    <div style={{ marginTop: "1rem", textAlign: "right" }}>
                        <Button
                            onClick={() =>
                                openInNewTab(
                                    `https://google.com/search?q=${encodeURIComponent(
                                        viewCustomerMapping.mappable_entry
                                            .trade_team_customer
                                    )}+${encodeURIComponent(
                                        viewCustomerMapping.mappable_entry
                                            .postcode
                                    )}`
                                )
                            }
                            style={{ marginRight: "1rem" }}
                        >
                            Search Entry
                        </Button>
                        {queueType && queueType !== "accepted" && (
                            <>
                                <Button
                                    onClick={() => {
                                        handleRejectCustomerMapping([
                                            {
                                                ...convertMap(
                                                    viewCustomerMapping
                                                ),
                                            },
                                        ]);
                                    }}
                                    style={{ marginRight: "1rem" }}
                                >
                                    Reject Link
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleAcceptCustomerMapping([
                                            {
                                                ...convertMap(
                                                    viewCustomerMapping
                                                ),
                                            },
                                        ]);
                                    }}
                                >
                                    Accept Link
                                </Button>
                            </>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default CustomerMapping;
