import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import {
    Button,
    PageTitle,
    Panel,
    Row,
    Column,
    useAddToast,
    Loading,
} from "@cortexglobal/rla-components";

import { CubeLoader, ButtonDropdown } from "@cortexglobal/lens-components";
import { useHistory } from "react-router";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CampaignContainer = styled.div`
    padding-top: 2em;
`;

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div`
    padding: 1em;

    & td,
    th {
        padding-bottom: 1em;
    }
`;

const TagContainer = styled.div`
    display: flex;
`;

const Tag = styled.div`
    background: ${({ status }) => {
        if (status === "active") {
            return "#4da251";
        }
        if (status === "draft") {
            return "#e67927";
        }
        if (status === "deleted") {
            return "#d43232";
        }
    }};

    color: white;
    border-radius: 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0 1em;
    margin-left: 1em;
    text-transform: uppercase;
`;

const BoldTd = styled.td`
    font-weight: bold;
    width: 200px;
`;

const ViewPointsCampaign = ({ match }) => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [campaignData, setCampaignData] = useState();
    const addToast = useAddToast();

    const getCampaign = () => {
        setLoading(true);

        axios
            .get(`/api/v1/points/${match.params.id}`)
            .then(({ data }) => {
                console.log("main data", data);
                setCampaignData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting campaigns.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    const handleActivateCampaign = () => {
        axios
            .put(`/api/v1/points/activate`, {
                ids: [campaignData.id],
            })
            .then(({ data }) => {
                updateStatusVisual("active");
                setUpdateLoading(false);

                addToast({
                    type: "success",
                    content: "Campaign successfully activated",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setUpdateLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error activating your campaign.",
                    showFor: 5000,
                });
            });
    };

    const handleDisableCampaign = () => {
        axios
            .put(`/api/v1/points/disable`, {
                ids: [campaignData.id],
            })
            .then(({ data }) => {
                updateStatusVisual("draft");
                setUpdateLoading(false);

                addToast({
                    type: "success",
                    content: "Campaign successfully disabled",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setUpdateLoading(false);

                addToast({
                    type: "alert",
                    content: "There was an error disabling your campaign.",
                    showFor: 5000,
                });
            });
    };

    const handleDeleteCampaign = () => {
        axios
            .put(`/api/v1/points/delete`, {
                ids: [campaignData.id],
            })
            .then(({ data }) => {
                updateStatusVisual("deleted");
                setUpdateLoading(false);

                addToast({
                    type: "success",
                    content: "Campaign successfully deleted",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setUpdateLoading(false);

                addToast({
                    type: "alert",
                    content: "There was an error deleting your campaign.",
                    showFor: 5000,
                });
            });
    };

    const updateStatusVisual = (status) => {
        setCampaignData({
            ...campaignData,
            status: status,
        });
    };

    useEffect(() => {
        getCampaign();
    }, []);

    return (
        <>
            <PageTitle
                title={
                    <TagContainer>
                        {campaignData && campaignData.title}
                        <Tag status={campaignData && campaignData.status}>
                            {campaignData && campaignData.status}
                        </Tag>
                    </TagContainer>
                }
                expanded
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: "1em" }}>
                        <ButtonDropdown
                            color="secondary"
                            onClick={() => {
                                history.push("/rewards/campaigns");
                            }}
                            actions={[]}
                            name="primary"
                        >
                            <FontAwesomeIcon icon={faCaretLeft} /> Back to
                            Points Promotions
                        </ButtonDropdown>
                    </span>
                    {campaignData && (
                        <span>
                            <ButtonDropdown
                                onClick={() => {
                                    history.push(
                                        `/rewards/campaigns/edit/${match.params.id}`
                                    );
                                }}
                                name="primary"
                                actions={[
                                    ...(campaignData.status !== "active"
                                        ? [
                                              {
                                                  name: "Activate",
                                                  onClick: () => {
                                                      setUpdateLoading(true);
                                                      handleActivateCampaign();
                                                  },
                                              },
                                          ]
                                        : []),
                                    ...(campaignData.status !== "draft"
                                        ? [
                                              {
                                                  name: "Disable",
                                                  onClick: () => {
                                                      setUpdateLoading(true);
                                                      handleDisableCampaign();
                                                  },
                                              },
                                          ]
                                        : []),
                                    ...(campaignData.status !== "deleted"
                                        ? [
                                              {
                                                  name: "Delete",
                                                  onClick: () => {
                                                      setUpdateLoading(true);
                                                      handleDeleteCampaign();
                                                  },
                                              },
                                          ]
                                        : []),
                                ]}
                            >
                                Edit
                            </ButtonDropdown>
                        </span>
                    )}

                    {updateLoading && (
                        <span
                            style={{
                                marginLeft: "2em",
                                marginTop: "0.5em",
                            }}
                        >
                            <CubeLoader size={20} margin="0" />
                        </span>
                    )}
                </div>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && campaignData && (
                <CampaignContainer>
                    <Row expanded>
                        <Column>
                            <Panel style={{ padding: "0" }}>
                                <PanelHeader>Campaign Details</PanelHeader>
                                <PanelBody>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <BoldTd>Internal Name:</BoldTd>
                                                <td>
                                                    <span
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {
                                                            campaignData.internal_name
                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>PERKS Type:</BoldTd>
                                                <td>
                                                    <span
                                                        style={{
                                                            textTransform:
                                                                "uppercase",
                                                        }}
                                                    >
                                                        {campaignData.perks_type
                                                            .replace(
                                                                /[\-]/g,
                                                                " "
                                                            )
                                                            .trim()}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>Source:</BoldTd>
                                                <td>
                                                    <span
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {campaignData.source}
                                                    </span>
                                                </td>
                                            </tr>

                                            {campaignData.customer_types &&
                                                !!campaignData.customer_types
                                                    .length && (
                                                    <tr>
                                                        <BoldTd>
                                                            Customer Types:
                                                        </BoldTd>
                                                        <td>
                                                            {campaignData.customer_types.map(
                                                                (
                                                                    customer_type,
                                                                    index
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            customer_type
                                                                        }
                                                                        {index <
                                                                            campaignData
                                                                                .customer_types
                                                                                .length -
                                                                                1 && (
                                                                            <>
                                                                                ,{" "}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}

                                            <tr>
                                                <BoldTd>Frequency:</BoldTd>
                                                <td>
                                                    <span
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {campaignData.frequency}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>Start:</BoldTd>
                                                <td>
                                                    {new Date(
                                                        campaignData.date_from
                                                    ).toDateString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>End:</BoldTd>
                                                <td>
                                                    {new Date(
                                                        campaignData.date_to
                                                    ).toDateString()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </PanelBody>
                            </Panel>
                        </Column>
                    </Row>
                    <Row expanded>
                        <Column>
                            <Panel style={{ padding: "0" }}>
                                <PanelHeader>Points</PanelHeader>
                                <PanelBody>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <BoldTd>Calculation:</BoldTd>
                                                <td>
                                                    {campaignData.calculation
                                                        .replace(
                                                            /([A-Z])/g,
                                                            " $1"
                                                        )
                                                        .trim()}
                                                </td>
                                            </tr>
                                            {campaignData.display_categories &&
                                                !!campaignData
                                                    .display_categories
                                                    .length && (
                                                    <tr>
                                                        <BoldTd>
                                                            Categories:
                                                        </BoldTd>
                                                        <td>
                                                            {campaignData.display_categories.map(
                                                                (
                                                                    category,
                                                                    index
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            category
                                                                        }
                                                                        {index <
                                                                            campaignData
                                                                                .display_categories
                                                                                .length -
                                                                                1 && (
                                                                            <>
                                                                                ,{" "}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}

                                            {campaignData.display_sub_categories &&
                                                !!campaignData
                                                    .display_sub_categories
                                                    .length && (
                                                    <tr>
                                                        <BoldTd>
                                                            Sub Categories:
                                                        </BoldTd>
                                                        <td>
                                                            {campaignData.display_sub_categories.map(
                                                                (
                                                                    range,
                                                                    index
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {range}
                                                                        {index <
                                                                            campaignData
                                                                                .display_sub_categories
                                                                                .length -
                                                                                1 && (
                                                                            <>
                                                                                ,{" "}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}

                                            {campaignData.ranges &&
                                                !!campaignData.ranges
                                                    .length && (
                                                    <tr>
                                                        <BoldTd>Ranges:</BoldTd>
                                                        <td>
                                                            {campaignData.ranges.map(
                                                                (
                                                                    range,
                                                                    index
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {range}
                                                                        {index <
                                                                            campaignData
                                                                                .ranges
                                                                                .length -
                                                                                1 && (
                                                                            <>
                                                                                ,{" "}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}

                                            {campaignData.multiplier && (
                                                <tr>
                                                    <BoldTd>
                                                        Additional Points per
                                                        Pound:
                                                    </BoldTd>
                                                    <td>
                                                        {campaignData.multiplier.toLocaleString()}
                                                    </td>
                                                </tr>
                                            )}

                                            {campaignData.points && (
                                                <tr>
                                                    <BoldTd>
                                                        Bonus Points:
                                                    </BoldTd>
                                                    <td>
                                                        {campaignData.points}
                                                    </td>
                                                </tr>
                                            )}

                                            {campaignData.minimum_spend && (
                                                <tr>
                                                    <BoldTd>
                                                        Minimum Spend:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            campaignData.minimum_spend
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </PanelBody>
                            </Panel>
                        </Column>
                    </Row>
                </CampaignContainer>
            )}
        </>
    );
};

export default ViewPointsCampaign;
