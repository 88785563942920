import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
    Button,
    Column,
    FormikDatePicker,
    FormikInput,
    FormikAdvancedSelect,
    Panel,
    Row,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";
import { CubeLoader } from "@cortexglobal/lens-components";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

const steps = ["Details", "Points"];

const StepTitle = styled.h3`
    font-weight: normal;
    margin-bottom: 1em;
`;

const Schema = Yup.object().shape({
    title: Yup.string().required("Required"),
    date_from: Yup.date().required("Required"),
    data_to: Yup.date().required("Required"),
    frequency: Yup.string().required("Required"),
    source: Yup.string().required("Required"),
    calculation: Yup.string().required("Required"),
    minimum_spend: Yup.number(),
    multiplier: Yup.number(),
    points: Yup.number(),
    categories: Yup.array().of(Yup.number()).nullable(),
    sub_categories: Yup.array().of(Yup.number()).nullable(),
    ranges: Yup.array().of(Yup.string()).nullable(),
    customer_types: Yup.array().of(Yup.string()).nullable(),
});

const PointsCampaignBuilder = ({ handleSubmit, loading, values }) => {
    const addToast = useAddToast();
    const [currentStep, setCurrentStep] = useState(1);

    const [categories, setCategories] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [rangesLoading, setRangesLoading] = useState(false);

    const [subCategories, setSubCategories] = useState([]);
    const [subCategoriesLoading, setSubCategoriesLoading] = useState(false);

    const onSaveCampaign = (values) => {
        // Remove values that aren't needed depending on calculation
        handleSubmit({
            ...values,
            ...((values.calculation === "Tactical" ||
                values.calculation === "TacticalAuthorisedRepairer") && {
                points: undefined,
                minimum_spend: undefined,
            }),
            ...(values.calculation === "TacticalBonus" && {
                multiplier: undefined,
            }),
            ...(values.calculation === "DynamicSpend" && {
                points: undefined,
                minimum_spend: undefined,
                categories: undefined,
                ranges: undefined,
                sub_categories: undefined,
            }),
        });
    };

    const getData = () => {
        setCategoriesLoading(true);
        setRangesLoading(true);
        axios
            .get(`/api/v1/getAllCategories`)
            .then(({ data }) => {
                setCategoriesLoading(false);
                setCategories(data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting categories.",
                    showFor: 5000,
                });
            });
        axios
            .get(`/api/v1/getRanges`)
            .then(({ data }) => {
                setRangesLoading(false);
                setRanges(data.data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting ranges.",
                    showFor: 5000,
                });
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const getSubCategories = ({
        event,
        selectedCategories,
        selectedRanges,
    }) => {
        event.preventDefault();
        setSubCategoriesLoading(true);

        console.log(selectedCategories, selectedRanges);

        axios
            .put(`/api/v1/getSubCategories`, {
                categories: selectedCategories,
                ranges: selectedRanges,
            })
            .then(({ data }) => {
                setSubCategoriesLoading(false);
                console.log(data);
                setSubCategories(data);
            })
            .catch((e) => {
                setSubCategoriesLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error getting sub categories.",
                    showFor: 5000,
                });
            });
    };

    const nextStep = (event) => {
        event.preventDefault();
        setCurrentStep(currentStep + 1);
    };

    const previousStep = (event) => {
        event.preventDefault();
        setCurrentStep(currentStep - 1);
    };

    const isMonday = (date) => {
        const myDate = new Date(date);
        const day = myDate.getDay();
        return day === 1;
    };

    const isSunday = (date) => {
        const myDate = new Date(date);
        const day = myDate.getDay();
        return day === 0;
    };

    const isLastDay = (date) => {
        const myDate = new Date(date);
        const test = new Date(myDate.getTime()),
            month = test.getMonth();

        test.setDate(test.getDate() + 1);
        return test.getMonth() !== month;
    };

    const isFirstDay = (date) => {
        const myDate = new Date(date);
        const test = new Date(myDate.getTime()),
            month = test.getMonth();

        test.setDate(test.getDate() - 1);
        return test.getMonth() !== month;
    };

    if (rangesLoading || categoriesLoading) return <CubeLoader />;

    return (
        <>
            <StepTitle>
                <strong>Step {currentStep}:</strong> {steps[currentStep - 1]}
            </StepTitle>
            <Panel style={{ padding: "2em" }}>
                <Formik
                    initialValues={{
                        title: "",
                        date_from: new Date().toDateString(),
                        date_to: new Date().toDateString(),
                        frequency: "",
                        calculation: "",
                        minimum_spend: "",
                        multiplier: "",
                        points: "",
                        categories: [],
                        sub_categories: [],
                        ranges: [],
                        customer_types: [],
                        ...values,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Schema}
                >
                    {({ isSubmitting, values, setFieldValue, errors }) => {
                        const firstStepComplete =
                            !!values.title &&
                            !!values.frequency &&
                            !!values.date_from &&
                            !!values.date_to;

                        const secondStepComplete = !!values.calculation;

                        return (
                            <Form>
                                {currentStep === 1 && (
                                    <>
                                        <Row>
                                            <Column>
                                                <FormikInput
                                                    value={values.title}
                                                    width="100%"
                                                    labelWidth={100}
                                                    name="title"
                                                    label="Title"
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column>
                                                <FormikInput
                                                    value={values.internal_name}
                                                    width="100%"
                                                    labelWidth={100}
                                                    name="internal_name"
                                                    label="Internal Name For Promotion"
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column>
                                                <FormikAdvancedSelect
                                                    value={values.perks_type}
                                                    options={[
                                                        {
                                                            text: "DISTRIGO PERKS",
                                                            value: "perks",
                                                        },
                                                        {
                                                            text: "AR PERKS",
                                                            value: "ar-perks",
                                                        },
                                                    ]}
                                                    name="perks_type"
                                                    label="PERKS Type"
                                                />
                                            </Column>
                                        </Row>
                                        {console.log(values)}
                                        {values.perks_type === "perks" && (
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        value={values.source}
                                                        options={[
                                                            {
                                                                text: "Speeder",
                                                                value: "speeder",
                                                            },
                                                            {
                                                                text: "FaapJ",
                                                                value: "faapj",
                                                            },
                                                        ]}
                                                        name="source"
                                                        label="Source"
                                                    />
                                                </Column>
                                            </Row>
                                        )}
                                        {values.perks_type === "ar-perks" && (
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        value={values.source}
                                                        options={[
                                                            {
                                                                text: "Speeder",
                                                                value: "speeder",
                                                            },
                                                        ]}
                                                        name="source"
                                                        label="Source"
                                                    />
                                                </Column>
                                            </Row>
                                        )}

                                        {values.perks_type === "perks" && (
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        multi={true}
                                                        value={
                                                            values.customer_types
                                                        }
                                                        name="customer_types"
                                                        label="Customer Type"
                                                        options={[
                                                            {
                                                                text: "IMT",
                                                                value: "IMT",
                                                            },
                                                            {
                                                                text: "Bodyshop",
                                                                value: "Bodyshop",
                                                            },
                                                            {
                                                                text: "Transmission Specialist",
                                                                value: "Transmission Specialist",
                                                            },
                                                            {
                                                                text: "Euro Repar Car Service",
                                                                value: "Euro Repar Car Service",
                                                            },
                                                            {
                                                                text: "Used Car Sales",
                                                                value: "Used Car Sales",
                                                            },
                                                            {
                                                                text: "Vehicle Hire and Leasing",
                                                                value: "Vehicle Hire and Leasing",
                                                            },
                                                            {
                                                                text: "Recovery",
                                                                value: "Recovery",
                                                            },
                                                            {
                                                                text: "MOT Centre",
                                                                value: "MOT Centre",
                                                            },
                                                            {
                                                                text: "Citroen C1 Racing",
                                                                value: "Citroen C1 Racing",
                                                            },
                                                        ]}
                                                    />
                                                </Column>
                                            </Row>
                                        )}
                                        {values.perks_type === "ar-perks" && (
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        multi={true}
                                                        value={
                                                            values.customer_types
                                                        }
                                                        name="customer_types"
                                                        label="Customer Type"
                                                        options={[
                                                            {
                                                                text: "Authorised Repairer",
                                                                value: "Authorised Repairer",
                                                            },
                                                        ]}
                                                    />
                                                </Column>
                                            </Row>
                                        )}

                                        <Row>
                                            <Column>
                                                <FormikAdvancedSelect
                                                    value={values.frequency}
                                                    options={[
                                                        {
                                                            text: "Daily",
                                                            value: "daily",
                                                        },
                                                        {
                                                            text: "Weekly",
                                                            value: "weekly",
                                                        },
                                                        {
                                                            text: "Monthly",
                                                            value: "monthly",
                                                        },
                                                    ]}
                                                    name="frequency"
                                                    label="Points Frequency"
                                                />
                                            </Column>
                                        </Row>
                                        {values.frequency && (
                                            <Row>
                                                <Column large={6}>
                                                    <FormikDatePicker
                                                        filterDate={
                                                            values.frequency ===
                                                            "weekly"
                                                                ? isMonday
                                                                : values.frequency ===
                                                                  "monthly"
                                                                ? isFirstDay
                                                                : () => true
                                                        }
                                                        value={values.date_from}
                                                        name="date_from"
                                                        label="Date From"
                                                    />
                                                </Column>
                                                <Column large={6}>
                                                    <FormikDatePicker
                                                        filterDate={
                                                            values.frequency ===
                                                            "weekly"
                                                                ? isSunday
                                                                : values.frequency ===
                                                                  "monthly"
                                                                ? isLastDay
                                                                : () => true
                                                        }
                                                        value={values.date_to}
                                                        name="date_to"
                                                        label="Date To"
                                                    />
                                                </Column>
                                            </Row>
                                        )}
                                        <Row>
                                            <Column
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Button
                                                    disabled={
                                                        !firstStepComplete
                                                    }
                                                    onClick={nextStep}
                                                >
                                                    Next Step
                                                </Button>
                                            </Column>
                                        </Row>
                                    </>
                                )}

                                {currentStep === 2 && (
                                    <>
                                        {values.perks_type === "perks" && (
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        value={
                                                            values.calculation
                                                        }
                                                        options={[
                                                            {
                                                                text: "Dynamic Spend",
                                                                value: "DynamicSpend",
                                                            },
                                                            {
                                                                text: "Tactical",
                                                                value: "Tactical",
                                                            },
                                                            {
                                                                text: "Tactical Bonus",
                                                                value: "TacticalBonus",
                                                            },
                                                        ]}
                                                        name="calculation"
                                                        label="Calculation"
                                                    />
                                                </Column>
                                            </Row>
                                        )}
                                        {values.perks_type === "ar-perks" && (
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        value={
                                                            values.calculation
                                                        }
                                                        options={[
                                                            {
                                                                text: "AR PERKS",
                                                                value: "TacticalAuthorisedRepairer",
                                                            },
                                                        ]}
                                                        name="calculation"
                                                        label="Calculation"
                                                    />
                                                </Column>
                                            </Row>
                                        )}

                                        {(values.calculation === "Tactical" ||
                                            values.calculation ===
                                                "TacticalBonus" ||
                                            values.calculation ===
                                                "TacticalAuthorisedRepairer") && (
                                            <>
                                                <Row>
                                                    <Column>
                                                        <FormikAdvancedSelect
                                                            multi={true}
                                                            value={
                                                                values.categories
                                                            }
                                                            name="categories"
                                                            label="Categories"
                                                            options={categories}
                                                        />
                                                    </Column>
                                                </Row>
                                                <Row>
                                                    <Column>
                                                        <FormikAdvancedSelect
                                                            multi={true}
                                                            value={
                                                                values.ranges
                                                            }
                                                            name="ranges"
                                                            label="Ranges"
                                                            options={ranges}
                                                        />
                                                    </Column>
                                                </Row>
                                                <Row>
                                                    <Column
                                                        style={{
                                                            marginBottom: "1em",
                                                        }}
                                                    >
                                                        <SubmitButton
                                                            submitting={
                                                                subCategoriesLoading
                                                            }
                                                            onClick={(event) =>
                                                                getSubCategories(
                                                                    {
                                                                        event,
                                                                        selectedCategories:
                                                                            values.categories,
                                                                        selectedRanges:
                                                                            values.ranges,
                                                                    }
                                                                )
                                                            }
                                                            disabled={
                                                                (!values.ranges ||
                                                                    !values
                                                                        .ranges
                                                                        .length) &&
                                                                (!values.categories ||
                                                                    !values
                                                                        .categories
                                                                        .length)
                                                            }
                                                        >
                                                            Get Sub Categories
                                                        </SubmitButton>
                                                    </Column>
                                                </Row>
                                                {!!subCategories.length && (
                                                    <Row>
                                                        <Column>
                                                            <FormikAdvancedSelect
                                                                multi={true}
                                                                value={
                                                                    values.sub_categories
                                                                }
                                                                name="sub_categories"
                                                                label="Sub Categories"
                                                                options={subCategories.reduce(
                                                                    (
                                                                        accumulator,
                                                                        currentValue
                                                                    ) => {
                                                                        return [
                                                                            ...accumulator,
                                                                            ...currentValue.subCategories,
                                                                        ];
                                                                    },
                                                                    []
                                                                )}
                                                            />
                                                        </Column>
                                                    </Row>
                                                )}
                                            </>
                                        )}

                                        {(values.calculation ===
                                            "DynamicSpend" ||
                                            values.calculation === "Tactical" ||
                                            values.calculation ===
                                                "TacticalAuthorisedRepairer") && (
                                            <Row>
                                                <Column>
                                                    <FormikInput
                                                        value={
                                                            values.multiplier
                                                        }
                                                        type="number"
                                                        width="100%"
                                                        labelWidth={100}
                                                        name="multiplier"
                                                        label="Additional Points per Pound"
                                                    />
                                                </Column>
                                            </Row>
                                        )}

                                        {values.calculation ===
                                            "TacticalBonus" && (
                                            <>
                                                <Row>
                                                    <Column>
                                                        <FormikInput
                                                            value={
                                                                values.minimum_spend
                                                            }
                                                            type="number"
                                                            width="100%"
                                                            labelWidth={100}
                                                            name="minimum_spend"
                                                            label="Minimum Spend"
                                                        />
                                                    </Column>
                                                </Row>
                                                <Row>
                                                    <Column>
                                                        <FormikInput
                                                            value={
                                                                values.points
                                                            }
                                                            type="number"
                                                            width="100%"
                                                            labelWidth={100}
                                                            name="points"
                                                            label="Bonus Points"
                                                        />
                                                    </Column>
                                                </Row>
                                            </>
                                        )}

                                        <Row>
                                            <Column
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        marginRight: "1rem",
                                                    }}
                                                    onClick={previousStep}
                                                >
                                                    Previous Step
                                                </Button>
                                                <SubmitButton
                                                    submitting={loading}
                                                    disabled={
                                                        !secondStepComplete
                                                    }
                                                    onClick={() =>
                                                        onSaveCampaign(values)
                                                    }
                                                >
                                                    Save Campaign
                                                </SubmitButton>
                                            </Column>
                                        </Row>
                                    </>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </Panel>
        </>
    );
};

export default PointsCampaignBuilder;
